
import React from 'react';
import { useTheme } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

const Nike = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <Box>
      <Grid container spacing={4} direction={isMd ? 'row' : 'column-reverse'}>
        <Grid item xs={12} md={6} data-aos={isMd ? 'fade-right' : 'fade-up'}>
          <Box marginBottom={4}>
          <Box marginTop={{ xs: 2, sm: 4 }}>
              <Typography variant={'h4'} sx={{ fontWeight: 700 }}
              align={'justify'}>
                Our Faculty
              </Typography>
              <br />
              <Typography color="text.secondary" align={'justify'}></Typography>
            </Box>
            <Typography component='p' align='justify' color="text.secondary">
            We have well experienced faculty with their outstanding performance who support liberal learning approach, which would help the children in critical and lateral thinking along with independence of mind.
<br/><br/>
We have teachers who are well qualified, trained and experienced with right attitude and values, having maturity over the content of their subject.
<br/><br/>
The teachers are classroom managers and facilitators who are always available to guide and to council the students even after school hours.
<br/><br/>
We train the faculty, time to time to update with the latest and most appropriate methods in teaching and learning.
            </Typography>
 
          </Box>
        </Grid>
        <Grid
          item
          container
          justifyContent="center"
          alignItems="center"
          xs={12}
          md={6}
          sx={{
            display: { xs: '2', md: 'flex' },
          }}
        >
          <Box component={Card} boxShadow={4} height={1} width={1}>
            <Box
              component={CardMedia}
              height={1}
              width={1}
              minHeight={250}
              image="https://i.imgur.com/6u0ClfO.jpg"
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Nike;